import Camino from './../resources/articles/camino.jpg';
import Muerte from './../resources/articles/muerte.jpg';

const essays = [
  {
      title: '¿Y luego de la muerte?',
      description: 'Todos conocemos lo que ocurre cuando fallece un ser querido. Luego de un tiempo, intentamos aprender a vivir sin esa persona a la que amamos. Sin embargo, hay un espacio del misterio del límite definitivo que no nos conforma nunca, a pesar de las múltiples explicaciones. Es porque no existen. En este caso, nos acercamos tímidamente al inmenso tema del fin de la vida y a través de ello, reflexionar sobre nuestra mágica vibrante existencia.',
      date: '10 de noviembre, 2024',
      image: Muerte,
      link: 'y-luego-de-la-muerte',
      minutes: '5',
      tags: ['muerte', 'incomprensión', 'esperanza'],
      text: [
          'El filósofo Karl Jaspers, de la corriente existencialista, menciona en su libro La Filosofía (1953) que existen ciertas experiencias en la vida que nos produce una especie de bloqueo. Son ocasiones en las cuales entramos en crisis y nos movilizamos para buscar respuestas, situaciones que nos obligan a detenernos y profundizar, para preguntarnos acerca de nuestra existencia. Cuestiones que no tienen una resolución sencilla, pero además tampoco pueden obviarse, olvidarse ni modificar, aunque quisiéramos, porque no se les puede dar una respuesta total.  Son ellos: el dolor, el sufrimiento, la lucha, la culpa, el azar -como la muerte- por ejemplo.  Y esta última es, precisamente, la más concluyente de todas, la única experiencia definitiva del ser humano.',
          'Pienso que las demás situaciones podrían modificarse por instrumentos técnicos, por mutaciones internas o externas;  pero la muerte no puede cesar, es definitiva.  Y hay algo más serio, los humanos no sabemos en qué consiste ese final, porque nadie ha regresado del viaje eterno para contarnos qué sucedió después.  Tal vez por eso, la muerte nos causa ese temor profundo, ese terror esencial.  La gente  puede hablar de cómo ha sufrido, de cómo ha luchado, de cómo le ha dolido algo; pero nadie puede contar cómo es la muerte, cómo es su cara tenebrosa, y si es verdaderamente un "pasaje" hacia otro estado, o una condición de la nada y para siempre.',
          'Resulta asombroso, cuando alguien se atreve a hablar con certezas. ¿Quién de nosotros tiene evidencia de qué respecto a la muerte? La única seguridad es que moriremos, todo lo demás es un gran misterio.  A partir de allí, debemos aceptar que hablamos filosóficamente en términos de la más plena posibilidad, de lo cual resulta que ninguna teoría debería considerarse disparatada.  En lo que a mí respecta, tomo este tema con el respeto del que sabe que no ha visto, ni verá; es decir, del que conjetura. Hasta el momento, lo constatado consiste en un dejar de existir del modo que se venía viviendo, aunque haya una explicación orgánica.  Hay médicos que aseguran que la única muerte es la cerebral, porque allí está el yo, es decir, lo que la persona es.  No lo afirmaría, aunque la  medicina tenga sus definiciones perfectamente lógicas.  Somos conscientes de que hay mucho más allá de la ciencia, como la filosofía, la espiritualidad, e incluso; terrenos que aún no han sido esclarecidos, que se discuten. O tal vez,  disciplinas  aún desconocidas por completo.',
          'Si nos preguntamos acerca de lo qué sucede después de ese momento en que el cuerpo yace allí, sobre el curso que se inicia con el desarrollo de lo inerte, está claro que los procesos químicos culminan afectando a la carne muerta.  Pero, ¿y qué del espíritu? Llámese alma, energía o como se quiera. También habrá que postular y discutir sobre su destino y existencia. Lo terrible, lo absurdo de la muerte, es que no podemos comprender cómo ese cuerpo que vibraba con energía, que tuvo juventud y creatividad en todos los aspectos de la vida, que evolucionó a lo largo del tiempo, transformando todo a su alrededor, padeciendo y gozando, reclutando en huellas las circunstancias de un pedazo de la historia, ahora yace inerte.  Pero, sobre todo, no concebimos cómo pudo  amar y ser amada  con intensidad, como si la vida fuera para siempre, y ahora termina como una cáscara vacía; ya sin su movimiento, sin su sonrisa y sin sus ojos expresivos, sino en una quietud y un silencio incomprensible.  Ya no es más ‘esa persona’, y a veces resulta chocante decir que es ‘su cuerpo’. Nos resistimos a creer que el cuerpo sea un envase, porque la piel es su yo, los ojos son su yo, y las manos, también ‘es el yo de esa persona’.  No podemos comprender que de un momento a otro desapareció, ya no está.  Ahora yace ‘esto otro’ en su lugar, como si fuera una máscara horrible que retuvo sólo algún rasgo de lo que fue. Y no estará nunca más.',
          '¿Y adónde desapareció?  No estamos acostumbrados a aceptar que las cosas desaparecen.  En la naturaleza ‘nada se pierde’, todo se transforma, y en lo humano pasa algo similar: las energías no desaparecen, sólo se mueven a otro lugar o se mudan de apariencia.  Nada deja de ser.  Repulsa a la conciencia racional que algo desaparezca mágicamente, los magos verdaderos no existen y la ciencia descree de los brujos.  Sin embargo, con la muerte llega la desaparición, el ‘dejar de ser’ y ‘dejar de estar’, y este dejar de ser es ‘lo definitivo’, es ‘el límite’ humano.     Aunque  ayuda creer que luego ascendemos al Cielo, me atrevo a decir que esta idea nace sobre todo de una necesidad angustiosa, que sustituiría cualquier otra explicación, si hiciera falta.  Podríamos decir, por ejemplo,  que un hada nos toma en su vuelo y nos lleva al reino de los duendes, o que un extraterrestre invisible nos rapta en su nave y luego vivimos en otra galaxia. ¿Por qué no?',
          'Y es que tenemos ansia de eternidad, de ‘no acabamiento’.  En realidad, vivimos cada día como si fuéramos eternos, lo cual es un alivio para nuestra salud mental. Hace poco escuché un pensamiento inquietante: ‘vive como si fuera el último día, que algún día será verdad’.  No le deseo a nadie tenerlo en su mesa de luz. Los seres humanos pensamos cada minuto que no moriremos nunca, porque no podemos aceptar la desaparición.  Vivimos como si tuviéramos la seguridad de que no moriremos.  Luchamos, sufrimos y amamos así: para siempre; puede ser que después dejemos de amar, pero ‘mientras es’ es para siempre. Y cuando acaba la lucha, el sufrimiento y el amor, se mudan necesariamente o se transforman, pero no cesan.  Solo cesamos cuando se nos termina la vida.  Y es ese ‘cesar de ser’ nuestro límite definitivo, que no podemos comprender, el misterio más grande que nos envuelve sobre nuestro yo. La verdad más cierta y definitiva, pero de la cual todo ignoramos. Tal vez con la fe, se abra el mundo de la gratuidad, de la trascendencia y la esperanza.'
      ],
  },
  {
      additionalInfo: 'El texto fue escrito hace algunos años, sin precisión exacta.',
      title: 'Sobre el sentido de la vida',
      date: '28 de abril, 2024',
      description: 'El sentido de la vida es una búsqueda personal, una pregunta profunda que nos invita a reflexionar sobre quiénes somos y por qué estamos aquí.',
      image: Camino,
      link: 'sobre-el-sentido-de-la-vida',
      text: [
          'Cada cosa tiene su para qué, y por eso nunca se te ocurriría utilizar una tijera como martillo o preparar un alimento en una maceta.  Como diría el filósofo español Julián Marías, las cosas salen mal o alguien se perjudica cuando usamos mal lo que son.  En general no suele haber discusión acerca de para qué sirve cada cosa, incluso algunas, como las lavadoras, vienen con manual de instrucción. Ahora bien, si hablamos de los seres vivos, ya puede haber disidencias. Ante la pregunta: para qué existe una planta, como el trigo, cuál es el sentido de su existencia en el medio del campo; pueden ensayarse muchas respuestas: para convertirse en pan, para embellecer la pradera, para alimentar a las aves, entre otras opciones.  Del mismo modo, si nos preguntamos para qué existen los flamencos del reino animal, por ejemplo, seguramente habrá diferentes opiniones, aunque estamos seguros de su belleza.  Pero qué ocurre cuando nos preguntamos para qué existe el hombre, o cuál es el sentido de que exista alguien.  Las respuestas se multiplican aún más.  La Madre Teresa o Gandhi dirían que es el amor, aunque para un ermitaño como Zarathustra o el vecino refunfuñador de la cuadra, ya no estaríamos tan seguros.  Es muy probable que el vecino ni se preguntara acerca del sentido de la vida.  De hecho, convengamos en que la mayoría de la gente no reflexiona acerca del “sentido de la vida”.  Es muy común que la muerte nos sorprenda sin habernos preguntado algo similar.',
          'El sentido de la vida puede parecer “aquello para lo cual” uno fue creado, y esto tiene que ver con conocer “qué somos”, o más precisamente “quién soy”, “para qué estamos en el mundo”.  Y puede decirse que llegar a esta pregunta qué es “la pregunta”, a la que no todos llegan, es iniciar una búsqueda que probablemente no acabe jamás.  Pero estar en esa búsqueda es ya encontrar un poco el sentido, porque el hombre, como ninguna otra criatura es camino, apertura, libertad de ser como se proponga.  Dice T. S. Eliot: “Hasta que no extiendas tus alas nadie puede saber lo alto que llegarás”, ni siquiera tú llegas a saberlo, podríamos agregar.  Preguntarse por el sentido de “la vida” es haber llegado a la médula de la humanidad, es como haber hecho el esfuerzo de subir y estar ahora sobre la cima de la montaña mirando el paisaje empequeñecido.  Y estar así, en silencio,  inundado por una pregunta tan misteriosa como profunda, tan abarcadora como esencial.  Y quiero decir además:   no hay “la respuesta”, no hay “dos opciones”, “ni seis” entre las cuales elegir; nadie tiene el manual de instrucción de  su vida, mucho menos de la vida de otro.  Lo cual no deja de ser  maravilloso.  Sería decepcionante que mientras reposamos en la montaña, alguien nos descifrara los colores que nos tiene anonadados, como si alguien conociera el manual de su destino, como en la lavadora.',
          '“El sentido de la vida” es  trabajo de cada uno.  Es un camino que se recorre con la admiración de haber llegado a la pregunta, un sendero en el cuál los otros son, además de compañía, a veces  indicio.  El alma y el cuerpo suelen decir cuando nos acercamos y la felicidad se señala al transitar.  Y da lo mismo si mientras recorres barrer las veredas, investigas en la academia, curar enfermos o cultivar verduras; porque el sentido es el camino.  La actividad que harás mientras tanto es sólo circunstancial, un detalle.  Por eso lo importante no es lo que “tenés”, lo que “sabés”, o los honores que has depositado en los otros la sociedad.  Lo esencial de la vida humana no son los “cómo” sino el “qué”, o más bien el “quién sos”.  Y este “quién” es la pregunta más profunda y radical del hombre, es estar frente al punto a partir del cual todo puede ser diferente.  Es darse cuenta que allí, en ausencia definitiva de explicaciones, en el silencio más absoluto del alma se puede comenzar la búsqueda de “sí mismo”.  Y es también, disfrutar el gozo más profundo que nos habilita el hecho de ser humanos.'
      ],
  }
];

export default essays;
