import { HStack, Tag } from "@chakra-ui/react";


const Tags = ({ tags }) => {
  return (
      <HStack spacing={2} marginTop={2}>
          {tags?.map((tag) => {
              return (
                  <Tag size={'md'} variant="solid" backgroundColor={'#724162'} key={tag}>
                      {tag}
                  </Tag>
              )
          })}
      </HStack>
  )
}

export default Tags;