import { Box, Grid, HStack, Image, Text, useColorModeValue } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Tags from "./tags";
import silvia from './../../resources/silvia2.png';

const Essay = ({ essay }) => {
    const navigate = useNavigate();

    const handleClick = (link) => {
        navigate(`/blog/${link}`);
    };

    return (
        <>
            <Box className='blog-item'>
                <Box
                    display="flex"
                    flex="1"
                    position="relative"
                    flexDirection={'column'}
                    justifyContent={'start'}
                >
                    <Box
                        width={{ base: '100%', sm: '85%' }}
                        zIndex="2"
                    >
                        <Box textDecoration="none" _hover={{ textDecoration: 'none' }}>
                            <Image
                                borderRadius="lg"
                                src={essay?.image}
                                alt="some good alt text"
                                objectFit="contain"
                                onClick={() => handleClick(essay?.link)}
                                cursor={'pointer'}
                                style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}
                                maxH={'420px'}
                                width={'100%'}
                                backgroundColor={'white'}

                            />
                        </Box>
                    </Box>
                    <Box zIndex="1" width="100%" position="absolute" height="100%">
                        <Box
                            bgGradient={useColorModeValue(
                                'radial(#5d3f53 1px, transparent 1px)',
                                'radial(#5d3f53 1px, transparent 1px)',
                            )}
                            backgroundSize="20px 20px"
                            opacity="0.4"
                            height="100%"
                        />
                    </Box>
                </Box>
                <Box className='item-content' flex="1">
                    <Grid>
                      <Text
                          className='item-title'
                          onClick={() => handleClick(essay.link)}
                          cursor={'pointer'}
                      >
                          {essay.title}
                      </Text>
                      <Tags tags={essay?.tags} />
                      <Text className='item-text' maxWidth={'1000px'}>
                          {essay.description}
                      </Text>
                    </Grid>
                    <Box className='item-bottom-author'>
                        <HStack marginTop="2" spacing="5" display="flex" alignItems="center" width={'80%'}>
                            <Image
                                width={'60px'}
                                src={silvia}
                                alt={'Foto de silvia'}
                                objectFit={'cover'}
                                clipPath='polygon(0 0, 100% 0, 100% 80%, 0 80%)'
                            />
                            <Grid textAlign={'start'}>
                                <Text as={'b'}>Silvia H. Prost</Text>
                                <Text className='essay-date'>
                                    <Text>{essay.date}</Text>
                                </Text>
                            </Grid>
                        </HStack>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Essay;
